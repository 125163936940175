import React, { createContext } from "react";
import httpClient, { loginUtils }  from "../../utils/httpClient";

const KeycloackContext = createContext()

const KeycloakProvider = ({ children }) => {

  const logoutUserFromKeycloak = () => {

    httpClient.put(
      `/auth/logout`,
      null,
      (response) => {
        window.location.assign(loginUtils.getLoginPath());
      },
      (error) => {
        console.log(`Error has occured executing:/auth/logout, error:${error}`)
      }
    );
  }

  return (
    <KeycloackContext.Provider value={{ logoutUserFromKeycloak }}>
      {children}
    </KeycloackContext.Provider>
  );
};

export { KeycloakProvider, KeycloackContext }