import React from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';

const ApplicationCardWrapper = styled.div`
  flex-basis: 30%;
  background-color: #f2f9fc;
  max-width: 300px;
  height: 182px;
  margin: 10px;
  margin-left: ${(props) => (props.index === 0 ? (props.nbrApplications === 4 ? 0 : '10px') : '10px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center
  flex-wrap: no-wrap;
   :hover {
    background-image: linear-gradient(to right, #007DBA , #45BEAA);
    color: white;
    cursor: pointer;
     img {
      filter: brightness(0) invert(1);
     }
   }
`;

const ApplicationName = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: -20px 10px 2px 10px;
  text-align: center;
`;

const ApplicationContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 2px 10px;
  text-align: center;
`;

const ApplicationCard = ({ name, link, index, nbrApplications }) => {
  const handleClick = () => {
    window.location.href = link;
  };
  return (
    <ApplicationCardWrapper onClick={handleClick} index={index} nbrApplications>
      <div>
        <img src={`/assets/applicationsIcons/${name}.svg`} alt={`${name} logo`}></img>
      </div>
      <ApplicationName>
        <FormattedMessage id={`applications.title.${name}`} defaultMessage={name} />
      </ApplicationName>
      <ApplicationContent>
        <FormattedMessage id={`applications.subtitle.${name}`} defaultMessage={name} />
      </ApplicationContent>
    </ApplicationCardWrapper>
  );
};

export default injectIntl(ApplicationCard);
