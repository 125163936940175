import { createGlobalStyle } from 'styled-components';

function getFontUrl(props) {
  return `url("${props.theme.font.url}/${props.theme.font.family}.woff2")`;
}

const GlobalStyleComponent = createGlobalStyle`
  @font-face {
    font-family: ${(props) => props.theme.font.family};
    src: ${(props) => getFontUrl(props)} format("woff2 supports variations"), ${(props) =>
  getFontUrl(props)} format("woff2-variations");
    font-weight: ${(props) => props.theme.font.weights.light} ${(props) => props.theme.font.weights.semibold};
    font-style: normal;
    font-display: swap;
  }

  html, body {
    font-family: ${(props) => `${props.theme.font.family}, ${props.theme.font.fallbacks}`};
    font-weight: ${(props) => props.theme.font.weights.regular};
  }
  html {
    /* fix rem value */
    font-size: 62.5%;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.16;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.greyScale.body};
    background: ${(props) => props.theme.colors.greyScale.white};
  }
  body {
    margin: 0 auto;
    height: 100%;
    background: white;
  }
  #RobotoFont {
    font-family: Roboto;
    font-weight: ${(props) => props.theme.font.weights.regular};
    src: url(${(props) => props.theme.font.url}/Roboto-Regular.woff) format('woff');
  }
`;

export default GlobalStyleComponent;
