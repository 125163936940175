import { useEffect, useState } from "react";
import { httpClientUtils, loginUtils } from "./httpClient";

const useFetch = (url) => {
    const [apiError, setApiError] = useState(null)
    const [apiData, setApiData] = useState(null)
    const [apiLoading, setApiLoading] = useState(false)
    const baseUrl = httpClientUtils.getSetting('apiDomain') + httpClientUtils.getSetting('welcomeApiPath');
  
    useEffect(() => {
      setApiLoading(true)
        async function call() {
          try {
             await fetch(baseUrl + url, {
               method: "GET",
               credentials: 'include'
            })
            .then(response => {
              if (response.status === 401) {
                window.location.assign(loginUtils.getLoginPath());
              }

              if (response.ok) {
                response.json().then(json => {
                  setApiData(json);
                  setApiLoading(false)
                  return {apiData , apiLoading , apiError}
                });
              }
              else {
                setApiError(response)
              }
                })
          } catch (err) {
              setApiError(err)
            } finally {
              setApiLoading(false)
            }
        }
      call()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  return { apiData, apiLoading, apiError }
};

export default useFetch;