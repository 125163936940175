import { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import httpClient, { httpClientUtils } from './httpClient';
import I18nContext from '../libs/IntlProvider/IntlContext';
import { UserInfoContext } from 'og-merchant-portal-react-library';

export const useGetDataFromQuickLinks = (props) => {
  const { locale } = useContext(I18nContext);
  const [isLoading, setIsLoading] = useState(true);
  const [realmQuickLinks, setRealmQuickLinks] = useState();
  const userId = useContext(UserInfoContext).profile.userId;
  const realm = httpClientUtils.getSetting('keycloakConfig').realm;
  const quickLinksPath = httpClientUtils.getSetting('appProviderApiPath');

  useEffect(() => {
    httpClient.get(
      `${quickLinksPath}/${realm}/users/${userId}/quicklinks`,
      (response) => {
        setRealmQuickLinks(response);
        setIsLoading(false);
      },
      (error) => {
        console.error('Something went wrong while fetching help menu items: ', error);
        setIsLoading(false);
      }
    );
  }, [quickLinksPath, realm, userId]);

  function getQuickLinksData(realmQuickLinks, locale) {
    const quickLinks = [];
    const sortedresults = realmQuickLinks?.filter((obj) => {
      return obj.name === props.component;
    });

    if (sortedresults && sortedresults.length > 0) {
      let filterdQuickLinksByLocale = [];
      filterdQuickLinksByLocale = sortedresults[0].items.filter((obj) => {
        return obj.language.toLowerCase() === locale;
      });

      if (filterdQuickLinksByLocale.length === 0) {
        filterdQuickLinksByLocale = sortedresults[0].items.filter((obj) => {
          return obj.language.toLowerCase() === 'en';
        });
      }

      filterdQuickLinksByLocale[0].links.forEach((element) =>
        quickLinks.push({
          id: element.key,
          url: element.link,
          text: <FormattedMessage id={`quicklinks.${element.key}`} defaultMessage={element.key} />,
        })
      );
    }
    return quickLinks;
  }

  const quickLinksItems = getQuickLinksData(realmQuickLinks, locale);

  const helpMenuItems = {
    id: 'help-header',
    text: <FormattedMessage id={`quicklinks.useFulLinks`} defaultMessage={'Useful links'} />,
    icon: 'helpOutline',
    subItems: quickLinksItems,
  };

  const footerItems = quickLinksItems;

  return { isLoading, helpMenuItems, footerItems };
};
