import React from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';

const TitleWrapper = styled.div`
  height: 30px;
  margin-left: 245px;
  margin-top: 56px;
  div {
    line-height: 30px;
    span:last-of-type {
      color: ${(props) => props.theme.colors.primary.regular};
    }
  }
  color: #141317;
  font-size: 24px;
  font-weight: 600;
  @media (max-width: ${(props) => props.theme.medias.xs.max}px) {
    margin: 20px 25px;
    font-size: 16px;
  }
`;

const Title = ({ user }) => {
  return (
    <TitleWrapper>
      <div>
        <span data-testid={'data-test-hello'}>
          <FormattedMessage id={`title.hello`} defaultMessage={'Hello '} />
        </span>
        <span data-testid={'data-test-user'}>
          {user.profile.firstName} {user.profile.lastName}!
        </span>
      </div>
    </TitleWrapper>
  );
};

export default injectIntl(Title);
