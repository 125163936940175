import React, { useContext, useState, useEffect } from 'react';
import GlobalStyle from '../../libs/GlobalStyle/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { UserInfoContext } from 'og-merchant-portal-react-library';
import IntlProvider from '../../libs/IntlProvider/IntlProvider';
import styled from 'styled-components';
import { getSessionStorage } from '../../utils/sessionHelper';
import Header from '../Header/Header';
import Title from '../Title/Title';
import Applications from '../Applications/Applications';
import Footer from '../Footer/Footer';

const StyledPageContainer = styled.div`
  transition: margin-left 300ms;
  width: 100%;
  min-width: 1440px;
  overflow: hidden;
`;

const getAvailableTranslationsList = () => {
  const Locales = window.appSettings.availableLocales.split(',');
  return Locales.map((locale) => {
    return locale.toLowerCase();
  });
};

const Welcome = () => {
  const user = useContext(UserInfoContext);
  const [theme, setTheme] = useState();
  const [availableTranslations] = useState(getAvailableTranslationsList);
  const [language] = useState(getSessionStorage('locale') || user.profile?.language || 'fr');
  useEffect(() => {
    Promise.all([fetch('/themes/theme.json', { cache: 'no-cache' }).then((response) => response.json())])
      .then(([theme]) => {
        setTheme(theme);
      })
      .catch((error) => {
        console.error('The portal theme could not be loaded. Please retry later.');
        throw new Error(error);
      });
  }, [user]);

  if (theme && user !== null) {
    return (
      <IntlProvider language={language} availableTranslations={availableTranslations}>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <StyledPageContainer>
              <Header />
              <Title user={user} />
              <Applications />
              <Footer />
            </StyledPageContainer>
          </>
        </ThemeProvider>
      </IntlProvider>
    );
  } else {
    return null;
  }
};

export default Welcome;
