import React from 'react';
import UserProvider from './libs/UserProvider/UserProvider';
import Welcome from './components/Welcome/Welcome';
import { KeycloakProvider } from './libs/KeycloakProvider/KeycloakProvider';

const App = () => {

  return (
    <KeycloakProvider>
        <UserProvider>
          <Welcome />
        </UserProvider>
    </KeycloakProvider>
  );
}

export default App;